import './bootstrap';
import './global.d';
import {initOneTabSignIn, signOutButton} from './modules/google/one-tab-sign-in';

// meta images, fonts
// @ts-ignore
import.meta.glob([
  '../images/**',
  '../fonts/**',
]);
// added to window object for alpine dev tool
// @ts-ignore
if (import.meta.env.MODE === 'development') {
  //import { Alpine } from '@/alpineJS'
  import('@/alpineJS').then(({Alpine}) => {
    window.Alpine = Alpine
  })
}

window.signOutButton = signOutButton;

window.onload = () => {
  initOneTabSignIn();
}
